import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheckCircle, faArrowAltCircleUp, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';

const unidirectional = new Set([ 'null', 'manual' ]);
const reverse = new Set([ 'rarity', 'level' ]);

class SortButton extends Component {
    handleClick = () => {
        let sort = this.props.sort;
        if (this.isActive()) {
            sort += this.props.active.endsWith('-asc') ? '-desc' : '-asc'
        } else {
            sort += reverse.has(sort) ? '-desc' : '-asc';
        }
        this.props.onToggle(this.props.arg, sort);
    }

    isActive = () => {
        return this.props.active.startsWith(this.props.sort);
    }

    icon = () => {
        if (this.isActive()) {
            if (unidirectional.has(this.props.sort)) return faCheckCircle;
            return this.props.active.endsWith('-asc') ? faArrowAltCircleUp : faArrowAltCircleDown;
        } else {
            return faCircle;
        }
    }

    render() {
        if (!this.props.visible) return null;

        return (
            <button type="button" className="btn btn-primary text-nowrap" onClick={this.handleClick}>
                <FontAwesomeIcon icon={this.icon()} /> {this.props.label}
            </button>
        );
    }
}

SortButton.defaultProps = {
    visible: true
};

export default SortButton;