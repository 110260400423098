import { Component } from 'react';

import api from '../util/api';
import utils from '../util/utils';
import login from '../util/login';

// version should be incremented if the collection ever needs to be re-sorted or re-filtered
let version = 1;

const durations = [ '7days', '30days', '90days', '52weeks', 'max' ];
const virtual = { soulshapes: 'quests', skips: 'quests', manuscripts: 'quests' };
const norarity = { manuscripts: true, appearancesources: true };
const addcompleted = { reputations: true, achievements: true };

const parentTabs = {};
Object.keys(utils.CARDS).forEach(tab => utils.CARDS[tab].forEach(category => parentTabs[category + '-details'] = tab));

const MOGOPRIORITY = {
    3: 10, // Obtainable for Limited Time
    4: 20, // Bugged
    1: 30, // Unobtainable
    2: 40, // Never Implemented
};
const MOGFPRIORITY = {
    0: 1, // alliance
    1: 1 // horde
};
const MOGSORT = (a, b) => (MOGOPRIORITY[a.unobtainable] || 0) + (MOGFPRIORITY[a.faction] || 0) - (MOGOPRIORITY[b.unobtainable] || 0) - (MOGFPRIORITY[b.faction] || 0);

class Collections extends Component {
    constructor(props) {
        super(props);
        this.toggleOff = {
            start: 0,
            showAccount: false,
            showAccountObtainable: false,
            showCharacter: false,
            showCharacterObtainable: false,
            showProfessionRankings: false,
            showProfessionRankingsObtainable: false,
            showStats: false,
            showAlts: false,
            showHistory: false,
            chartType: 'line',
            historyCategory: null,
            historyDuration: null,
            showAchievements: false,
            showMounts: false,
            showPets: false,
            showTitles: false,
            showRecipes: false,
            showReputations: false,
            showQuests: false,
            showToys: false,
            showAppearances: false,
            showAppearancesources: false,
            showHeirlooms: false,
            showHunterpets: false,
            showSoulshapes: false,
            showManuscripts: false,
            showSkips: false,
            showPatches: false,
            showFactions: false,
            showClasses: false,
            showRaces: false,
            showRarity: false,
            showLevels: false,
            showAdvanced: false,
            showSources: false,
            showProfessions: false,
            showBinds: false,
            showSlots: false,
            showTypes: false,
            showQualities: false,
            showCategories: false
        };
        this.collectionState = {
            rankings1: {},
            rankings2: {},
            stats1: null,
            stats2: null,
            alts1: null,
            alts2: null,
            achievements1: null,
            achievements2: null,
            mounts1: null,
            mounts2: null,
            pets1: null,
            pets2: null,
            titles1: null,
            titles2: null,
            recipes1: null,
            recipes2: null,
            reputations1: null,
            reputations2: null,
            quests1: null,
            quests2: null,
            toys1: null,
            toys2: null,
            appearances1: null,
            appearances2: null,
            appearancesources1: null,
            appearancesources2: null,
            heirlooms1: null,
            heirlooms2: null,
            hunterpets1: null,
            hunterpets2: null,
            soulshapes1: null,
            soulshapes2: null,
            manuscripts1: null,
            manuscripts2: null,
            skips1: null,
            skips2: null,
        };

        // add state for each possible chart
        for (let duration of durations) {
            this.collectionState['history' + duration + '1'] = null;
            this.collectionState['history' + duration + '2'] = null;
        }

        this.initialState = {
            ...this.toggleOff,
            ...this.collectionState,
            message: null,
            leaderboardstats: null
        };

        // migration code
        if (localStorage.sort && !localStorage.sort.includes('-')) localStorage.removeItem('sort');
        for (let key of ['showCollectedThis','showCollectedAlt','showNotCollected','showObtainable','showCollectedUnobtainable','showUnobtainable','showNeverImplemented','showTimeLimited','showPresent','showMissing','showIncluded','showExcluded']) {
            if (localStorage.getItem(key)) localStorage.setItem('filter.' + key, localStorage.getItem(key));
            localStorage.removeItem(key);
        }

        this.state = {
            ...this.initialState,
            showCollectedThis: localStorage.getItem('filter.showCollectedThis') !== 'false',
            showCollectedAlt: localStorage.getItem('filter.showCollectedAlt') !== 'false',
            showNotCollected: localStorage.getItem('filter.showNotCollected') !== 'false',
            showCollectedExclusive: false,
            showObtainable: localStorage.getItem('filter.showObtainable') !== 'false',
            showCollectedUnobtainable: localStorage.getItem('filter.showCollectedUnobtainable') !== 'false',
            showUnobtainable: localStorage.getItem('filter.showUnobtainable') !== 'false',
            showBugged: localStorage.getItem('filter.showBugged') !== 'false',
            showNeverImplemented: localStorage.getItem('filter.showNeverImplemented') === 'true',
            showTimeLimited: localStorage.getItem('filter.showTimeLimited') !== 'false',
            showPresent: localStorage.getItem('filter.showPresent') !== 'false',
            showMissing: localStorage.getItem('filter.showMissing') !== 'false',
            showIncluded: localStorage.getItem('filter.showIncluded') !== 'false',
            showExcluded: localStorage.getItem('filter.showExcluded') === 'true',
            showTradable: localStorage.getItem('filter.showTradable') !== 'false',
            showNotTradable: localStorage.getItem('filter.showNotTradable') !== 'false',
            showSortName: true,
            showSortSource: true,
            showSortProfession: true,
            showSortCategory: true,
            showSortRarity: true,
            showSortPatch: true,
            showSortId: true,
            filter: '',
            regex: null,
            sort: localStorage.getItem('sort') || 'name-asc',
            group: localStorage.getItem('group') || 'null-asc',
            pageSize: parseInt(localStorage.getItem('pageSize')) || 40,
            patch: null,
            hidePatches: this.initSet('filter.hidePatches'),
            hideFactions: this.initSet('filter.hideFactions'),
            hideClasses: this.initSet('filter.hideClasses'),
            hideRaces: this.initSet('filter.hideRaces'),
            hideSources: this.initSet('filter.hideSources'),
            hideProfessions: this.initSet('filter.hideProfessions'),
            hideCategories: this.initSet('filter.hideCategories'),
            hideBinds: this.initSet('filter.hideBinds'),
            hideSlots: this.initSet('filter.hideSlots'),
            hideTypes: this.initSet('filter.hideTypes'),
            hideQualities: this.initSet('filter.hideQualities'),
            hideZones: this.initSet('filter.hideZones'),
            collapsed: new Set(),
            categories: [],
            rarity: this.initRange('filter.rarity'),
            seen: this.initRange('filter.seen'),
            level: this.initRange('filter.level')
        };
    }

    componentDidMount() {
        if (this.props.category) this.browseCollection(this.props.category);
        else this.loadTab(this.props.tab);
    }

    componentDidUpdate(previous) {
        const sensitive = [ 'region1', 'realm1', 'character1', 'region2', 'realm2', 'character2' ];
        if (sensitive.some(key => this.props[key] !== previous[key])) {
            this.handleToggle('reset', false);
            this.loadTab(this.props.tab);
            return;
        }

        if (this.props.category !== previous.category) this.browseCollection(this.props.category);
        if (this.props.tab !== previous.tab) this.loadTab(this.props.tab);
    }

    initSet(name) {
        const remember = localStorage.rememberFilters === 'true';
        if (!remember) return new Set();

        const stored = localStorage.getItem(name);
        if (!stored) return new Set();

        return new Set(JSON.parse(stored));
    }

    modifySet(name, id, variants) {
        const value = new Set(this.state[name]);
        if (id === 'All') {
            value.clear();
        } else if (id === 'None') {
            for (let v of variants) {
                value.add(v);
            }
        } else {
            const ids = Array.isArray(id) ? id : [ id ];
            const toggle = value.has(ids[0]);
            for (let i of ids) {
                toggle ? value.delete(i) : value.add(i);
            }
        }

        localStorage.setItem('filter.' + name, JSON.stringify(Array.from(value)));
        return this.setState({ start: 0, [name]: value, version: version++ });
    }

    modifyToggle(name) {
        const value = !this.state[name];
        localStorage.setItem('filter.' + name, value.toString());
        return this.setState({ start: 0, [name]: value, version: version++ });
    }

    initRange(name) {
        const remember = localStorage.rememberFilters === 'true';
        if (!remember) return { mintext: '', maxtext: '' };

        const stored = localStorage.getItem(name);
        if (!stored) return { mintext: '', maxtext: '' };

        return JSON.parse(stored);
    }

    modifyRange(name, changes) {
        const value = { ...this.state[name], ...changes };
        localStorage.setItem('filter.' + name, JSON.stringify(value));
        return this.setState({ start: 0, [name]: value, version: version++ });
    }

    loadTab = async (category) => {
        if (category?.endsWith('-details')) {
            // need to make sure the parent tab is loaded
            this.loadTab(parentTabs[category]);

            // then load the details tab
            const detail = category.substring(0, category.length - 8);
            this.toggleHistory(detail, '30days');

            return;
        }

        switch (category) {
            case 'account-rankings': return this.toggleAccount();
            case 'account-rankings-obtainable': return this.toggleAccountObtainable();
            case 'character-rankings': return this.toggleCharacter();
            case 'character-rankings-obtainable': return this.toggleCharacterObtainable();
            case 'profession-rankings': return this.toggleProfessionRankings();
            case 'profession-rankings-obtainable': return this.toggleProfessionRankingsObtainable();
            case 'stats': return this.toggleStats();
            case 'alts': return this.toggleAlts();
            case 'achievements': return this.toggleCollection('achievements');
            case 'mounts': return this.toggleCollection('mounts');
            case 'pets': return this.toggleCollection('pets');
            case 'titles': return this.toggleCollection('titles');
            case 'recipes': return this.toggleCollection('recipes');
            case 'reputations': return this.toggleCollection('reputations');
            case 'quests': return this.toggleCollection('quests');
            case 'toys': return this.toggleCollection('toys');
            case 'appearances': return this.toggleCollection('appearances');
            case 'appearancesources': return this.toggleCollection('appearancesources');
            case 'heirlooms': return this.toggleCollection('heirlooms');
            case 'hunterpets': return this.toggleCollection('hunterpets');
            case 'soulshapes': return this.toggleCollection('soulshapes');
            case 'skips': return this.toggleCollection('skips');
            case 'manuscripts': return this.toggleCollection('manuscripts');
            default: throw new Error('Unrecognized tab');
        }
    }

    handleToggle = async (category, arg1, arg2) => {
        switch (category) {
            case 'reset': 
                {
                    const resetCache = {};
                    Object.keys(this.state).filter(key => key?.startsWith('api#')).forEach(key => resetCache[key] = null);

                    // arg1 = refresh
                    const toState = arg1 ? this.collectionState : this.initialState;
                    return this.setState({ ...toState, ...resetCache });
                }
            case 'scores':
                // refresh needs to reset and reload entire tab
                // otherwise just load rankings now that we have the scores
                if (arg1.refresh) this.handleToggle('reset', true);

                if (arg1.split === 'right') {
                    return this.setState({ scores2: arg1.scores, character2: arg1.character }, () => arg1.refresh ? this.loadTab(this.props.tab) : this.loadRankings(this.props.region2, this.props.realm2, '2', this.props.tab));
                } else {
                    return this.setState({ scores1: arg1.scores, character1: arg1.character }, () => arg1.refresh ? this.loadTab(this.props.tab) : this.loadRankings(this.props.region1, this.props.realm1, '1', this.props.tab));
                }
            case 'history': return this.toggleHistory(arg1, arg2);
            case 'chart': return this.setState({ chartType: arg1 });
            case 'uploaded':
                // after an upload, reset our collections without clearing the api cache
                return this.setState(this.collectionState, () => this.loadTab(this.props.tab));
            case 'collectedThis': return this.modifyToggle('showCollectedThis');
            case 'collectedAlt': return this.modifyToggle('showCollectedAlt');
            case 'notCollected': return this.modifyToggle('showNotCollected');
            case 'collectedExclusive': return this.modifyToggle('showCollectedExclusive');
            case 'obtainable': return this.modifyToggle('showObtainable');
            case 'collectedUnobtainable': return this.modifyToggle('showCollectedUnobtainable');
            case 'unobtainable': return this.modifyToggle('showUnobtainable');
            case 'bugged': return this.modifyToggle('showBugged');
            case 'neverImplemented': return this.modifyToggle('showNeverImplemented');
            case 'timeLimited': return this.modifyToggle('showTimeLimited');
            case 'present': return this.modifyToggle('showPresent');
            case 'missing': return this.modifyToggle('showMissing');
            case 'included': return this.modifyToggle('showIncluded');
            case 'excluded': return this.modifyToggle('showExcluded');
            case 'tradable': return this.modifyToggle('showTradable');
            case 'notTradable': return this.modifyToggle('showNotTradable');
            case 'sortby':
                localStorage.setItem('sort', arg1);
                return this.setState({ start: 0, sort: arg1, collapsed: new Set(), version: version++ });
            case 'groupby':
                localStorage.setItem('group', arg1);
                return this.setState({ start: 0, group: arg1, collapsed: new Set(), version: version++ });
            case 'patches': return this.setState({ showPatches: !this.state.showPatches });
            case 'patch': return this.modifySet('hidePatches', arg1, arg2);
            case 'factions': return this.setState({ showFactions: !this.state.showFactions });
            case 'faction': return this.modifySet('hideFactions', arg1, arg2);
            case 'classes': return this.setState({ showClasses: !this.state.showClasses });
            case 'class': return this.modifySet('hideClasses', arg1, arg2);
            case 'races': return this.setState({ showRaces: !this.state.showRaces });
            case 'race': return this.modifySet('hideRaces', arg1, arg2);
            case 'rarities': return this.setState({ showRarity: !this.state.showRarity });
            case 'rarity': return this.modifyRange('rarity', arg1);
            case 'seen': return this.modifyRange('seen', arg1);
            case 'levels': return this.setState({ showLevels: !this.state.showLevels });
            case 'level': return this.modifyRange('level', arg1);
            case 'advanced': return this.setState({ showAdvanced: !this.state.showAdvanced });
            case 'sources': return this.setState({ showSources: !this.state.showSources });
            case 'source': return this.modifySet('hideSources', arg1, arg2);
            case 'professions': return this.setState({ showProfessions: !this.state.showProfessions });
            case 'profession': return this.modifySet('hideProfessions', arg1, arg2);
            case 'binds': return this.setState({ showBinds: !this.state.showBinds });
            case 'bind': return this.modifySet('hideBinds', arg1, arg2);
            case 'slots': return this.setState({ showSlots: !this.state.showSlots });
            case 'slot': return this.modifySet('hideSlots', arg1, arg2);
            case 'types': return this.setState({ showTypes: !this.state.showTypes });
            case 'type': return this.modifySet('hideTypes', arg1, arg2);
            case 'qualities': return this.setState({ showQualities: !this.state.showQualities });
            case 'quality': return this.modifySet('hideQualities', arg1, arg2);
            case 'categories':
                {
                    if (this.state.showCategories) return this.setState({ showCategories: false });

                    const meta = await api.web('achievements');
                    const set = new Set();
                    meta.achievements.forEach(a => a.category && set.add(a.category));

                    const categories = Array.from(set);
                    categories.sort(utils.collator.compare);

                    return this.setState({ showCategories: true, categories });
                }
            case 'category': return this.modifySet('hideCategories', arg1, arg2);
            case 'zone': return this.modifySet('hideZones', arg1, arg2);
            case 'filter':
                return this.setState({ start: 0, filter: arg1, regex: utils.regex(arg1), version: version++ });
            case 'collapse':
                if (Array.isArray(arg1)) {
                    // the full list has been specified
                    return this.setState({ start: 0, collapsed: new Set(arg1), version: version++ });
                } else {
                    const collapsed = new Set(this.state.collapsed);
                    collapsed.has(arg1) ? collapsed.delete(arg1) : collapsed.add(arg1);

                    const start = this.state.pageSize * Math.trunc(arg2 / this.state.pageSize);

                    return this.setState({ start, collapsed, version: version++ });
                }
            case 'page':
                localStorage.setItem('pageSize', arg2);
                return this.setState({ start: arg1, pageSize: arg2 });
            default: throw new Error('Unrecognized category');
        }
    }

    async loadAlts(region, realm, character) {
        if (!login.contributor()) return; // permissions check

        const response = await api.get('characters/' + encodeURIComponent(region) + '/' + encodeURIComponent(realm) + '/' + encodeURIComponent(character) + '/alts');
        if (response.status !== 200) return null;

        const json = await response.json();
        const alts = json.collection;
        if (!Array.isArray(alts)) return null;

        const realms = {};
        alts.forEach(alt => {
            alt.priority = 1000 * alt.level + alt.averageItemLevel;
            if (!realms[alt.realm] || alt.priority > realms[alt.realm]) realms[alt.realm] = alt.priority;
        });
        alts.sort((a, b) => {
            if (a.realm !== b.realm) return realms[b.realm] - realms[a.realm];
            if (a.priority !== b.priority) return b.priority - a.priority;
            return a.name.localeCompare(b.name);
        });

        return alts.map(item => ({
            id: item.key,
            field: 'characters',
            link: '/characters/' + encodeURIComponent(item.region) + '/' + encodeURIComponent(item.realm) + '/' + encodeURIComponent(item.name),
            icon: item.thumbnail ? 'https://render.worldofwarcraft.com/' + item.region.toLowerCase() + '/character/' + item.thumbnail : null,
            name: item.name,
            textClass: 'class-' + item.class,
            source: (item.guildName ? '❮' + item.guildName + '❯ ' : '') + item.region.toUpperCase() + '-' + item.realm,
            faction: item.faction,
            account: true,
            character: true,
            alt: true,
            altLevel: item.level,
            altItemLevel: item.averageItemLevel,
            fulltext: (item.name || '') + '\n' + (item.guildName || '') + '\n' + (item.region + '-' + item.realm)
        }));
    }

    async loadHistory(region, realm, character, duration) {
        const response = await api.get('characters/' + encodeURIComponent(region) + '/' + encodeURIComponent(realm) + '/' + encodeURIComponent(character) + '/history?duration=' + encodeURIComponent(duration));
        if (response.status !== 200) return null;

        const json = await response.json();
        if (!Array.isArray(json.data)) return null;

        return json;
    }

    show(field) {
        const options = {
            showSortName: true,
            showSortSource: field === 'mounts' || field === 'pets' || field === 'titles' || field === 'recipes' || field === 'toys' || field === 'heirlooms' || field === 'soulshapes' || field === 'manuscripts',
            showSortProfession: field === 'recipes',
            showSortCategory:  field === 'quests' || field === 'achievements',
            showSortRarity: field !== 'manuscripts' && field !== 'appearancesources',
            showSortPatch: true,
            showSortId: true
        };

        let sort = this.state.sort;
        let group = this.state.group;

        // make sure selected sort/group are valid
        if (field !== 'alts') {
            if (!options['showSort' + sort.charAt(0).toUpperCase() + sort.substring(1, sort.indexOf('-'))]) sort = 'name-asc';
            if (!options['showSort' + group.charAt(0).toUpperCase() + group.substring(1, group.indexOf('-'))]) group = 'null-asc';
        }

        const tab = 'show' + field.charAt(0).toUpperCase() + field.substring(1);
        return { [tab]: true, show: field, ...options, sort, group, version: version++ };
    }

    async meta(field) {
        if (field.startsWith('appearances')) return this.metaappearances(field);
        return api.web(field);
    }

    // contains post-processing of appearances.json file
    async metaappearances(field) {
        const [ json1, json2, json3 ] = await Promise.all([ api.web('appearancesources'), api.web('appearancesets'), api.web('quests') ]);
        if (!json1?.appearancesources || !json2?.sets || !json3.quests) return null; // failed to load

        let sources = {};
        let visuals = {};
        let quests = {};

        // gather quests by id
        for (let q of json3.quests) {
            quests[q.id] = q.name;
        }

        // gather items by sourceid and visualid
        for (let item of json1.appearancesources) {
            // map blizzard source information
            const source = this.mapsource(item.source, quests);

            // clone to make sure we don't taint source file
            item = { ...item, ...source, field };

            sources[item.id] = item;

            // TODO should fix any missing visualids
            if (item.visualid) {
                visuals[item.visualid] ??= [];
                visuals[item.visualid].push(item);
            }
        }

        // add set info to sources
        for (let set of json2.sets) {
            for (let id of set.sourceids) {
                if (!sources[id]) continue; // TODO should fix any missing sources

                sources[id].sets ??= [];
                sources[id].sets.push(set);
            }
        }

        // done if we're loading just sources
        if (field === 'appearancesources') {
            return { appearancesources: Object.values(sources) };
        }

        // group by visualid and return a virtual item
        visuals = Object.values(visuals).map(group => {
            // try to pick item that best represents the appearance
            group.sort(MOGSORT);

            // combine sets from any source
            let sets = new Set();
            for (let item of group) {
                if (!item.sets) continue;

                for (let set of item.sets) {
                    sets.add(set);
                }
            }
            sets = sets.size ? Array.from(sets) : undefined;

            return { ...group[0], sets, id: group[0].visualid, shared: group };
        });

        return { appearances: visuals };
    }

    mapsource(source, quests) {
        if (!source) return { sourceicon: undefined, source: undefined }; // file not ready yet
        if (!source?.length) return { sourceicon: 'other', source: 'Unknown' };
        source = source[0]; // only return 1 source for now

        if (source.quest) {
            return { sourceicon: 'quest', source: quests[source.quest] || 'Quest' };
        }

        // assume mob drop here
        if (source.name) {
            return { sourceicon: 'drop', source: source.name + ' (' + source.zone + ')' };
        }

        // map blizzard constants
        switch (source.type) {
            case 1: return { sourceicon: 'drop', source: 'Drop' };
            case 2: return { sourceicon: 'quest', source: 'Quest' };
            case 3: return { sourceicon: 'vendor', source: 'Vendor' };
            case 4: return { sourceicon: 'drop', source: 'World Drop' };
            case 7: return { sourceicon: 'achievement', source: 'Achievement' };
            case 8: return { sourceicon: 'profession', source: 'Profession' };
            case 10: return { sourceicon: 'vendor', source: 'Trading Post' };
            default: return { sourceicon: 'other', source: 'Unknown' };
        }
    }

    async collection(region, realm, character, field) {
        const endpoint = 'characters/' + encodeURIComponent(region) + '/' + encodeURIComponent(realm) + '/' + encodeURIComponent(character) + '/' + encodeURIComponent(virtual[field] || field);

        let json = this.state['api#' + endpoint]; // cached for virtual fields
        if (!json) {
            const response = await api.get(endpoint);
            if (response.status !== 200) return null;

            json = await response.json();
            this.setState({ ['api#' + endpoint]: json });
        }

        // TODO make stats collection more consistent so this isn't needed?
        if (field === 'stats') return json;

        // merge in any uploaded data
        const map = {};
        json.collection.forEach(item => map[item.id] = item);

        const flag = addcompleted[virtual[field] || field];
        const upload = JSON.parse(localStorage.getItem('collection.' + field + '.' + json.account)) || [];
        upload.forEach(id => {
            if (flag && !map[id]?.completed) {
                map[id] = { id, completed: true, uploaded: true };
            } else if (!map[id]) {
                map[id] = { id, uploaded: true };
            }
        });

        // clone object so we don't change what's in cache
        return { ...json, collection: Object.values(map) };
    }

    async rarity(field) {
        if (norarity[field]) {
            return { [field]: { hide: true } };
        } else if (virtual[field]) {
            const json = await api.web(virtual[field] + 'rarity');
            json[field] = json[virtual[field]];
            return json;
        } else {
            return api.web(field + 'rarity');
        }
    }

    async browseCollection(field) {
        this.setState({ message: 'Loading...' });

        const [ meta, rarity ] = await Promise.all([ this.meta(field), this.rarity(field) ]);

        if (!meta?.[field] || !rarity?.[field]) {
            this.setState({ message: 'Error!' });
            return;
        }

        const collection = meta[field].map(item => ({
            ...item,
            rarity: rarity[field].hide ? undefined : (rarity[field][item.id] || 0),
            field: field
        }));
        this.index(collection);
        this.setState({ ...this.toggleOff, ...this.show(field), message: null, [field + '1']: collection });
    }

    async loadCollection(region, realm, character, field) {
        const [ json, meta, rarity ] = await Promise.all([ this.collection(region, realm, character, field), this.meta(field), this.rarity(field) ]);

        if (!json || !meta?.[field] || !rarity?.[field]) {
            this.setState({ message: 'Error!' });
            return;
        }

        const indexed = {};
        json.collection.forEach(i => indexed[i.id] = i);

        let all = [];
        const unrecognized = new Set(json.collection.map(i => i.id));
        meta[field].forEach(item => {
            all.push(item);
            unrecognized.delete(item.id);
        });

        if (!virtual[field]) {
            unrecognized.forEach(id => {
                all.push({
                    id: id,
                    name: 'Unknown #' + id
                });
            });
        }

        all = all.map(item => ({
            ...item,
            ...indexed[item.id],
            rarity: rarity[field].hide ? undefined : (rarity[field][item.id] || 0),
            field: field,
            account: Boolean(indexed[item.id]),
            character: Boolean(indexed[item.id] && indexed[item.id].alt !== 1),
            alt: Boolean(indexed[item.id]?.alt)
        }));

        this.index(all);
        return all;
    }

    index(collection) {
        const parens = text => text ? ' (' + text + ')' : '';
        collection.forEach(item => {
            item.fulltext = (item.id || '') + '\n' +
                (item.name || '') + '\n' +
                (item.shared?.map(item => item.name || '')?.join('\n') || '') + '\n' +
                (item.sets?.map(set => (set.name || '') + ' (' + (set.description || '') + ')\n' + (set.group || ''))?.join('\n') || '') + '\n' +
                (item.prof || '') + '\n' +
                (item.source || '') + parens(item.sourcestanding) + parens(item.sourcezone) + '\n' +
                (item.note || '') + '\n' +
                (item.category || '') + (item.subcategory ? ': ' : '') + (item.subcategory || '');
        });
    }

    loadRankings = async (region, realm, side, tab) => {
        if (parentTabs[tab]) tab = parentTabs[tab]; // use the parent tab for details pages
        if (!utils.CARDS[tab]) return; // not a rankings tab
        if (!this.state['scores' + side]) return; // main page not loaded yet

        const params = [];
        for (let category of utils.CARDS[tab]) {
            if (category in this.state['rankings' + side]) continue; // already loaded
            params.push(category + '=' + encodeURIComponent(this.state['scores' + side][category]));
        }
        if (!params.length) return; // everything already loaded

        const endpoint = 'rankings/' + encodeURIComponent(region) + '/' + encodeURIComponent(realm) + '?' + params.join('&');
        const response = await api.get(endpoint);
        const json = await response.json();
        this.setState(state => {
            // merge new rankings in with the old ones
            const key = 'rankings' + side;
            return { [key] : { ...state[key], ...json }};
        });
    }

    toggleAccount() {
        this.setState({ ...this.toggleOff, showAccount: true, show: 'account-rankings' });

        this.loadRankings(this.props.region1, this.props.realm1, '1', 'account-rankings');
        if (this.props.region2) this.loadRankings(this.props.region2, this.props.realm2, '2', 'account-rankings');
    }

    toggleAccountObtainable() {
        this.setState({ ...this.toggleOff, showAccountObtainable: true, show: 'account-rankings-obtainable' });

        this.loadRankings(this.props.region1, this.props.realm1, '1', 'account-rankings-obtainable');
        if (this.props.region2) this.loadRankings(this.props.region2, this.props.realm2, '2', 'account-rankings-obtainable');
    }

    toggleCharacter() {
        this.setState({ ...this.toggleOff, showCharacter: true, show: 'character-rankings' });

        this.loadRankings(this.props.region1, this.props.realm1, '1', 'character-rankings');
        if (this.props.region2) this.loadRankings(this.props.region2, this.props.realm2, '2', 'character-rankings');
    }

    toggleCharacterObtainable() {
        this.setState({ ...this.toggleOff, showCharacterObtainable: true, show: 'character-rankings-obtainable' });

        this.loadRankings(this.props.region1, this.props.realm1, '1', 'character-rankings-obtainable');
        if (this.props.region2) this.loadRankings(this.props.region2, this.props.realm2, '2', 'character-rankings-obtainable');
    }

    toggleProfessionRankings() {
        this.setState({ ...this.toggleOff, showProfessionRankings: true, show: 'profession-rankings' });

        this.loadRankings(this.props.region1, this.props.realm1, '1', 'profession-rankings');
        if (this.props.region2) this.loadRankings(this.props.region2, this.props.realm2, '2', 'profession-rankings');
    }

    toggleProfessionRankingsObtainable() {
        this.setState({ ...this.toggleOff, showProfessionRankingsObtainable: true, show: 'profession-rankings-obtainable' });

        this.loadRankings(this.props.region1, this.props.realm1, '1', 'profession-rankings-obtainable');
        if (this.props.region2) this.loadRankings(this.props.region2, this.props.realm2, '2', 'profession-rankings-obtainable');
    }

    async toggleStats() {
        if (this.state.stats1) {
            // already loaded
            return this.setState({ ...this.toggleOff, showStats: true, show: 'stats' });
        }

        this.setState({ message: 'Loading...' });

        const promises = [];
        promises.push(this.collection(this.props.region1, this.props.realm1, this.props.character1, 'stats'));
        if (this.props.region2) promises.push(this.collection(this.props.region2, this.props.realm2, this.props.character2, 'stats'));

        const stats = await Promise.all(promises);
        if (!stats.every(s => s)) return this.setState({ message: 'Error!' });

        if (stats[1]) this.setState({ stats2: stats[1]?.collection });
        this.setState({ ...this.toggleOff, showStats: true, show: 'stats', message: null, stats1: stats[0]?.collection });
    }

    async toggleAlts() {
        if (this.state.alts1) {
            this.setState({ ...this.toggleOff, ...this.show('alts') });
            return;
        }

        this.setState({ message: 'Loading...' });

        const promises = [];
        promises.push(this.loadAlts(this.props.region1, this.props.realm1, this.props.character1));
        if (this.props.region2) promises.push(this.loadAlts(this.props.region2, this.props.realm2, this.props.character2));

        const alts = await Promise.all(promises);
        if (!alts.every(a => a)) return this.setState({ message: 'Error!' });

        if (alts[1]) this.setState({ alts2: alts[1] });
        this.setState({ ...this.toggleOff, ...this.show('alts'), message: null, alts1: alts[0] });
    }

    async toggleHistory(category, duration) {
        if (this.state['history' + duration + '1']) {
            // already loaded
            this.setState({ showHistory: true, historyCategory: category, historyDuration: duration });
            return;
        }

        this.setState({ message: 'Loading...' });

        const promises = [];
        promises.push(this.loadHistory(this.props.region1, this.props.realm1, this.props.character1, duration));
        if (this.props.region2) promises.push(this.loadHistory(this.props.region2, this.props.realm2, this.props.character2, duration));

        // also load leaderboard stats
        api.web('leaderboardstats').then(json => this.setState({ leaderboardstats: json }));

        const history = await Promise.all(promises);
        if (!history.every(h => h)) return this.setState({ message: 'Error!' });

        if (history[1]) {
            this.setState({ ['history' + duration + '1']: [ history[0], history[1] ] });
            this.setState({ ['history' + duration + '2']: [ history[1], history[0] ] });
        } else {
            this.setState({ ['history' + duration + '1']: [ history[0] ] });
        }
        this.setState({ showHistory: true, historyCategory: category, historyDuration: duration, message: null });
    }

    async toggleCollection(field) {
        if (this.state[field + '1']) {
            // already loaded
            return this.setState({ ...this.toggleOff, ...this.show(field) });
        }

        this.setState({ message: 'Loading...' });

        const promises = [];
        promises.push(this.loadCollection(this.props.region1, this.props.realm1, this.props.character1, field));
        if (this.props.region2) promises.push(this.loadCollection(this.props.region2, this.props.realm2, this.props.character2, field));

        const collection = await Promise.all(promises);
        if (!collection.every(i => i)) return this.setState({ message: 'Error!' });

        if (collection[1]) {
            this.removeCommon(collection[0], collection[1]);
            this.setState({ [field + '2']: collection[1] });
        }
        this.setState({ ...this.toggleOff, ...this.show(field), message: null, [field + '1']: collection[0] });
    }

    removeCommon(collection1, collection2) {
        // index items
        const ids = new Set();

        const map1 = {};
        for (let item of collection1) {
            ids.add(item.id);
            map1[item.id] = item;
        }

        const map2 = {};
        for (let item of collection2) {
            ids.add(item.id);
            map2[item.id] = item;
        }

        // compare
        for (let id of ids) {
            if (map1[id]?.account === map2[id]?.account) {
                delete map1[id];
                delete map2[id];
            } else if (!map1[id]) {
                // handle unknown items
                map1[id] = { ...map2[id], character: false, account: false};
            } else if (!map2[id]) {
                // handle unknown items
                map2[id] = { ...map1[id], character: false, account: false};
            }
        }

        // update
        collection1.length = 0;
        collection1.push(...Object.values(map1));

        collection2.length = 0;
        collection2.push(...Object.values(map2));
    }

    render() {
        if (this.props.mode === 'compare') {
            const vs = (this.state.character1?.name || '') + ' vs ' + (this.state.character2?.name || '');
            document.title = vs + ' | Character Comparison | Data for Azeroth | World of Warcraft Leaderboards for Collectors';
        }

        const collection1 = { ...this.state, rankings: this.state.rankings1, stats: this.state.stats1, alts: this.state.alts1, achievements: this.state.achievements1, mounts: this.state.mounts1, pets: this.state.pets1, titles: this.state.titles1, recipes: this.state.recipes1, reputations: this.state.reputations1, quests: this.state.quests1, toys: this.state.toys1, heirlooms: this.state.heirlooms1, hunterpets: this.state.hunterpets1, soulshapes: this.state.soulshapes1, skips: this.state.skips1, manuscripts: this.state.manuscripts1, appearances: this.state.appearances1, appearancesources: this.state.appearancesources1 };
        const collection2 = { ...this.state, rankings: this.state.rankings2, stats: this.state.stats2, alts: this.state.alts2, achievements: this.state.achievements2, mounts: this.state.mounts2, pets: this.state.pets2, titles: this.state.titles2, recipes: this.state.recipes2, reputations: this.state.reputations2, quests: this.state.quests2, toys: this.state.toys2, heirlooms: this.state.heirlooms2, hunterpets: this.state.hunterpets2, soulshapes: this.state.soulshapes2, skips: this.state.skips2, manuscripts: this.state.manuscripts2, appearances: this.state.appearances2, appearancesources: this.state.appearancesources2 };

        // copy state for each possible chart
        for (let duration of durations) {
            collection1['history' + duration] = this.state['history' + duration + '1'];
            collection2['history' + duration] = this.state['history' + duration + '2'];
        }

        const props = {
            ...this.props,
            collection1,
            collection2,
            handleToggle: this.handleToggle
        };

        return this.props.render(props);
    }
}

export default Collections;