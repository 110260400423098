import React, { Component } from 'react';

import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle, faUndo, faFileDownload, faFileUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFlag as farFlag } from '@fortawesome/free-regular-svg-icons';

import Tag from '../components/Tag';
import Progress from './Progress';
import Loading from '../components/Loading';
import Paging from '../components/Paging';
import NumberInput from '../components/NumberInput';
import CollapseButton from '../components/CollapseButton';
import SortButton from '../components/SortButton';
import ToggleButton from '../components/ToggleButton';
import ToggleButtonWrapper from './ToggleButtonWrapper';
import AddonUpload from './AddonUpload';

import api from '../util/api';
import login from '../util/login';
import utils from '../util/utils';
import download from '../util/download';

import './Collection.scss';

const DAY = 24 * 60 * 60 * 1000;
const SORTS = {
    'name': (a, b) => utils.collator.compare(a.name, b.name),
    'source': (a, b) => utils.collator.compare(a.sourceicon, b.sourceicon) || utils.collator.compare(a.sourcezone, b.sourcezone) || utils.collator.compare(a.source, b.source) || utils.collator.compare(a.sourcestanding, b.sourcestanding),
    'profession': (a, b) => utils.collator.compare(a.prof || 'Other', b.prof || 'Other'),
    'category': (a, b) => utils.collator.compare(a.category || 'Unknown', b.category || 'Unknown') || utils.collator.compare(a.subcategory, b.subcategory),
    'rarity': (a, b) => (a.rarity || 0) - (b.rarity || 0),
    'id': (a, b) => (a.id || 0) - (b.id || 0),
    'patch': (a, b) => utils.collator.compare(a.patch, b.patch)
};

// create sorts for both directions
Object.keys(SORTS).forEach(key => {
    const fn = SORTS[key];
    SORTS[key + '-asc'] = fn;
    SORTS[key + '-desc'] = (a, b) => fn(b, a);
});

const GROUPS = {
    'source': a => utils.getFriendlySourceName(a.sourceicon),
    'profession': a => a.prof || 'Other',
    'category': a => a.category || 'Unknown',
    'patch': a => utils.getMajorVersion(a.patch) || 'v999'
};

// create sorts for each group
Object.keys(GROUPS).forEach(key => {
    const fn = GROUPS[key];
    GROUPS[key + '-asc'] = (a, b) => utils.collator.compare(fn(a), fn(b));
    GROUPS[key + '-desc'] = (a, b) => utils.collator.compare(fn(b), fn(a));
});

// special case - use expansion name
GROUPS['patch'] = a => utils.expansions[utils.getMajorVersion(a.patch)] || 'Unknown';
GROUPS['null'] = () => null;

const FILTERS = {
    // search bar
    keywords: {
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips', 'alts' ]),
        include: tpc => tpc.regex,
        eval: (tpc, item) => item.fulltext?.match(tpc.regex)
    },
    collectedThis: {
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips' ]),
        include: (tpc, browse, compare) => !browse && !compare && !tpc.showCollectedThis,
        eval: (tpc, item) => item.character ? tpc.showCollectedThis : !tpc.showCollectedThis,
        progress: true
    },
    collectedAlt: {
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips' ]),
        include: (tpc, browse, compare) => !browse && !compare && !tpc.showCollectedAlt,
        eval: (tpc, item) => (item.account && !item.character) ? tpc.showCollectedAlt : !tpc.showCollectedAlt,
        progress: true
    },
    notCollected: {
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips' ]),
        include: (tpc, browse, compare) => !browse && !compare && !tpc.showNotCollected,
        eval: (tpc, item) => item.account ? !tpc.showNotCollected : tpc.showNotCollected,
        progress: true
    },

    // filters
    factions: {
        label: 'Faction',
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'alts' ]),
        include: tpc => tpc.hideFactions.size,
        eval: (tpc, item) => !tpc.hideFactions.has(item.faction)
    },
    races: {
        label: 'Race',
        tabs: new Set([ 'quests', 'appearances', 'appearancesources' ]),
        include: tpc => tpc.hideRaces.size,
        eval: (tpc, item) => item.race ? !item.race.every(e => tpc.hideRaces.has(e)) : !tpc.hideRaces.has('')
    },
    classes: {
        label: 'Class',
        tabs: new Set([ 'quests', 'appearances', 'appearancesources' ]),
        include: tpc => tpc.hideClasses.size,
        eval: (tpc, item) => item.class ? !item.class.every(e => tpc.hideClasses.has(e)) : !tpc.hideClasses.has('')
    },
    patches: {
        label: 'Expansion',
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips' ]),
        include: tpc => tpc.hidePatches.size,
        eval: (tpc, item) => !tpc.hidePatches.has(item.patch|| 'Other')
    },
    sources: {
        label: 'Source',
        tabs: new Set([ 'mounts', 'pets', 'titles', 'recipes', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'soulshapes', 'manuscripts' ]),
        include: tpc => tpc.hideSources.size,
        eval: (tpc, item) => !tpc.hideSources.has(item.sourceicon || 'other')
    },
    slots: {
        label: 'Slot',
        tabs: new Set([ 'appearances', 'appearancesources' ]),
        include: tpc => tpc.hideSlots.size,
        eval: (tpc, item) => !tpc.hideSlots.has(item.slot || 'Other')
    },
    types: {
        label: 'Type',
        tabs: new Set([ 'appearances', 'appearancesources' ]),
        include: tpc => tpc.hideTypes.size,
        eval: (tpc, item) => !tpc.hideTypes.has(item.type || 'Other')
    },
    qualities: {
        label: 'Quality',
        tabs: new Set([ 'pets', 'appearances', 'appearancesources' ]),
        include: (tpc, browse) => tpc.hideQualities.size && (!tpc.showPets || !browse),
        eval: (tpc, item) => !tpc.hideQualities.has(item.quality || 0)
    },
    levels: {
        label: 'Level',
        tabs: new Set([ 'pets', 'heirlooms' ]),
        include: (tpc, browse) => !browse && (tpc.level.mintext || tpc.level.maxtext),
        eval: (tpc, item) => {
            const level = item.level || (tpc.showPets ? 1 : 0);
            const min = tpc.level.mintext ? tpc.level.minnumber : -Infinity;
            const max = tpc.level.maxtext ? tpc.level.maxnumber : Infinity;
            return level >= min && level <= max;
        }
    },
    binds: {
        label: 'Binds',
        tabs: new Set([ 'recipes', 'appearances', 'appearancesources' ]),
        include: tpc => tpc.hideBinds.size,
        eval: (tpc, item) => !tpc.hideBinds.has(item.bind || 0)
    },
    categories: {
        label: 'Category',
        tabs: new Set([ 'achievements' ]),
        include: tpc => tpc.hideCategories.size,
        eval: (tpc, item) => !tpc.hideCategories.has(item.category)
    },
    zones: {
        label: 'Category',
        tabs: new Set([ 'quests' ]),
        include: tpc => tpc.hideZones.size,
        eval: (tpc, item) => !tpc.hideZones.has(item.category|| 'Unknown')
    },
    professions: {
        label: 'Profession',
        tabs: new Set([ 'quests', 'recipes' ]),
        include: tpc => tpc.hideProfessions.size,
        eval: (tpc, item) => !tpc.hideProfessions.has(item.prof || '')
    },
    rarities: {
        label: 'Rarity',
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'soulshapes', 'skips' ]),
        include: tpc => tpc.rarity.mintext || tpc.rarity.maxtext,
        eval: (tpc, item) => {
            const min = tpc.rarity.mintext ? tpc.rarity.minnumber : -Infinity;
            const max = tpc.rarity.maxtext ? tpc.rarity.maxnumber : Infinity;
            return item.rarity > min && item.rarity <= max;
        }
    },
    seen: {
        label: 'Seen',
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'soulshapes', 'skips' ]),
        include: tpc => tpc.seen.mintext || tpc.seen.maxtext,
        eval: (tpc, item) => {
            const seen = item.seen || 0;
            const min = tpc.seen.mintext ? Date.now() - DAY * tpc.seen.minnumber : -Infinity;
            const max = tpc.seen.maxtext ? Date.now() - DAY * tpc.seen.maxnumber : Infinity;
            return seen >= min && seen <= max;
        }
    },

    // advanced
    obtainable: {
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips' ]),
        include: tpc => !tpc.showObtainable,
        eval: (tpc, item) => item.unobtainable ? !tpc.showObtainable : tpc.showObtainable
    },
    timeLimited: {
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips' ]),
        include: tpc => !tpc.showTimeLimited,
        eval: (tpc, item) => item.unobtainable === 3 ? tpc.showTimeLimited : !tpc.showTimeLimited
    },
    collectedUnobtainable: {
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips' ]),
        include: (tpc, browse, compare) => !browse && !compare && !tpc.showCollectedUnobtainable,
        eval: (tpc, item) => item.account && item.unobtainable && (item.unobtainable !== 3) ? tpc.showCollectedUnobtainable : !tpc.showCollectedUnobtainable
    },
    unobtainable: {
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips' ]),
        include: tpc => !tpc.showUnobtainable,
        eval: (tpc, item, browse, compare) => {
            const include = browse || compare || !item.account;
            return include && (item.unobtainable === 1) ? tpc.showUnobtainable : !tpc.showUnobtainable;
        }
    },
    neverImplemented: {
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips' ]),
        include: tpc => !tpc.showNeverImplemented,
        eval: (tpc, item, browse, compare) => {
            const include = browse || compare || !item.account;
            return include && (item.unobtainable === 2) ? tpc.showNeverImplemented : !tpc.showNeverImplemented;
        }
    },
    bugged: {
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips' ]),
        include: tpc => !tpc.showBugged,
        eval: (tpc, item, browse, compare) => {
            const include = browse || compare || !item.account;
            return include && (item.unobtainable === 4) ? tpc.showBugged : !tpc.showBugged;
        }
    },

    missing: {
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips' ]),
        include: tpc => !tpc.showPresent || !tpc.showMissing,
        eval: (tpc, item) => item.missing ? tpc.showMissing : tpc.showPresent
    },
    excluded: {
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips' ]),
        include: tpc => !tpc.showIncluded || !tpc.showExcluded,
        eval: (tpc, item) => item.excluded ? tpc.showExcluded : tpc.showIncluded
    },
    tradable: {
        tabs: new Set([ 'pets' ]),
        include: tpc => !tpc.showTradable || !tpc.showNotTradable,
        eval: (tpc, item) => item.tradable ? tpc.showTradable : tpc.showNotTradable
    },
    exclusive: {
        tabs: new Set([ 'achievements', 'mounts', 'pets', 'titles', 'reputations', 'recipes', 'quests', 'toys', 'appearances', 'appearancesources', 'heirlooms', 'hunterpets', 'soulshapes', 'manuscripts', 'skips' ]),
        include: tpc => tpc.showCollectedExclusive,
        eval: (tpc, item) => item.alt ? !tpc.showCollectedExclusive : tpc.showCollectedExclusive
    }
};

export default class Collection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zones: [],
            showZones: false,
            showSort: false,
            showGroup: false,
            showUpload: false,
            filtered: null,
            collapsed: null,
            groups: null,
            totals: null
        };
    }

    componentDidMount() {
        this.compute();
    }

    componentDidUpdate(prevProps, prevState) {
        const reset = prevState.filtered && !this.collection();
        const change = this.props.collection?.version !== prevProps.collection?.version;

        if (reset || change) this.compute();
    }

    toggleRememberFilter = () => {
        if (localStorage.rememberFilters === 'true') {
            localStorage.removeItem('rememberFilters');
        } else {
            localStorage.setItem('rememberFilters', 'true');
        }
        this.setState({ showCategory: Date.now() }); // just to re-render
    }

    toggleCollectedThis = () => {
        this.props.onToggle('collectedThis');
    }

    toggleCollectedAlt = () => {
        this.props.onToggle('collectedAlt');
    }

    toggleNotCollected = () => {
        this.props.onToggle('notCollected');
    }

    toggleCollectedExclusive = () => {
        this.props.onToggle('collectedExclusive');
    }

    toggleObtainable = () => {
        this.props.onToggle('obtainable');
    }

    toggleCollectedUnobtainable = () => {
        this.props.onToggle('collectedUnobtainable');
    }

    toggleUnobtainable = () => {
        this.props.onToggle('unobtainable');
    }

    toggleBugged = () => {
        this.props.onToggle('bugged');
    }

    toggleNeverImplemented = () => {
        this.props.onToggle('neverImplemented');
    }

    toggleTimeLimited = () => {
        this.props.onToggle('timeLimited');
    }

    togglePresent = () => {
        this.props.onToggle('present');
    }

    toggleMissing = () => {
        this.props.onToggle('missing');
    }

    toggleIncluded = () => {
        this.props.onToggle('included');
    }

    toggleExcluded = () => {
        this.props.onToggle('excluded');
    }

    toggleTradable = () => {
        this.props.onToggle('tradable');
    }

    toggleNotTradable = () => {
        this.props.onToggle('notTradable');
    }

    toggleZones = async (event) => {
        // need to grab the event right away
        const showZones = this.state.showZones ? false : event.currentTarget;

        // now dynamically create zone list
        if (!this.state.zones?.length) {
            const meta = await api.web('quests');

            const set = new Set(meta.quests.filter(q => q.category).map(q => q.category));
            set.add('Unknown');

            const zones = Array.from(set);
            zones.sort(utils.collator.compare);

            this.setState({ zones });
        }

        // now set toggle
        this.setState({ showZones });
    }

    toggleSort = (event) => {
        const showSort = this.state.showSort ? false : event.currentTarget;
        this.setState({ showSort });
    }

    toggleGroup = (event) => {
        const showGroup = this.state.showGroup ? false : event.currentTarget;
        this.setState({ showGroup });
    }

    changeRarityMin = (text, number) => {
        this.props.onToggle('rarity', { mintext: text, minnumber: number });
    }

    changeRarityMax = (text, number) => {
        this.props.onToggle('rarity', { maxtext: text, maxnumber: number });
    }

    changeSeenMin = (text, number) => {
        this.props.onToggle('seen', { mintext: text, minnumber: number });
    }

    changeSeenMax = (text, number) => {
        this.props.onToggle('seen', { maxtext: text, maxnumber: number });
    }

    changeLevelMin = (text, number) => {
        this.props.onToggle('level', { mintext: text, minnumber: number });
    }

    changeLevelMax = (text, number) => {
        this.props.onToggle('level', { maxtext: text, maxnumber: number });
    }

    resetFilters = () => {
        this.props.onToggle('filter', '');
        this.props.onToggle('rarity', { mintext: '', minnumber: undefined, maxtext: '', maxnumber: undefined });
        this.props.onToggle('seen', { mintext: '', minnumber: undefined, maxtext: '', maxnumber: undefined });
        this.props.onToggle('level', { mintext: '', minnumber: undefined, maxtext: '', maxnumber: undefined });

        if (!this.props.collection.showCollectedThis) this.toggleCollectedThis();
        if (!this.props.collection.showCollectedAlt) this.toggleCollectedAlt();
        if (!this.props.collection.showNotCollected) this.toggleNotCollected();

        // regular filters (easy)
        const filters = [ 'faction', 'race', 'class', 'source', 'bind', 'profession', 'category', 'zone', 'patch', 'slot', 'type', 'quality' ];
        filters.forEach(filter => this.props.onToggle(filter, 'All'));

        // advanced filters (cleaner way to do this?)
        if (!this.props.collection.showObtainable) this.toggleObtainable();
        if (!this.props.collection.showTimeLimited) this.toggleTimeLimited();
        if (!this.props.collection.showCollectedUnobtainable) this.toggleCollectedUnobtainable();
        if (!this.props.collection.showUnobtainable) this.toggleUnobtainable();
        if (!this.props.collection.showBugged) this.toggleBugged();
        if (this.props.collection.showNeverImplemented) this.toggleNeverImplemented();
        if (!this.props.collection.showPresent) this.togglePresent();
        if (!this.props.collection.showMissing) this.toggleMissing();
        if (!this.props.collection.showIncluded) this.toggleIncluded();
        if (this.props.collection.showExcluded) this.toggleExcluded();
        if (!this.props.collection.showTradable) this.toggleTradable();
        if (!this.props.collection.showNotTradable) this.toggleNotTradable();
        if (this.props.collection.showCollectedExclusive) this.toggleCollectedExclusive();
    }

    changeFilter = (event) => {
        this.props.onToggle('filter', event.target.value || '');
    }

    setPage = (start, size) => {
        start = size * ~~(start / size);
        this.props.onToggle('page', start, size);
        utils.scrollTo('collection');
    }

    download = () => {
        download.csv(this.state.filtered, this.props.collection);
    }

    toggleUpload = () => {
        this.setState({ showUpload: !this.state.showUpload });
    }

    finishUpload = (uploaded) => {
        this.setState({ showUpload: false });
        if (uploaded === true) this.props.onToggle('uploaded');
    }

    collection = () => {
        // collection key matches the name of the tab
        const tpc = this.props.collection;
        return tpc[tpc.show];
    }

    sort = (collection) => {
        const tpc = this.props.collection;
        if (tpc.showAlts) return collection; // no sorting for alts

        const sort1 = GROUPS[tpc.group]; // need to sort by grouping first
        const sort2 = SORTS[tpc.sort];
        const sort3 = SORTS[tpc.sort?.endsWith('-asc') ? 'name-asc' : 'name-desc']; // tie-breaker
        return collection.sort((a, b) => sort1?.(a, b) || sort2?.(a, b) || sort3?.(a, b));
    }

    filter = (progress) => {
        const tpc = this.props.collection;
        const gather = Object.values(FILTERS).filter(f => Boolean(progress) === Boolean(f.progress) && f.tabs.has(tpc.show) && f.include(tpc, this.props.browse, this.props.split)).map(f => f.eval);
        return item => gather.every(fn => fn(tpc, item, this.props.browse, this.props.split));
    }

    stats = (collection, alsoFilter) => {
        // prep our grouping function
        const tpc = this.props.collection;
        let groupfn = GROUPS[utils.upto(tpc.group, '-')] || GROUPS['null'];
        if (tpc.showAlts) groupfn = GROUPS['null'];

        // do rollup
        const rollup = {};
        collection = collection.filter((item, idx) => {
            const current = item.group = groupfn(item);

            rollup[current] ??= { name: current, start: idx, total: 0, character: 0, account: 0, excluded: 0, alliance: 0, horde: 0, missing: 0 };
            rollup[current].total++;

            if (item.account) rollup[current].account++;
            if (item.character) rollup[current].character++;

            if (item.excluded) rollup[current].excluded++;
            if (item.missing) rollup[current].missing++;
            if (item.faction === 0) rollup[current].alliance++;
            if (item.faction === 1) rollup[current].horde++;

            if (item.unobtainable && !item.account) {
                const key = 'unobtainable' + item.unobtainable;
                rollup[current][key] = (rollup[current][key] || 0) + 1;
            }

            return alsoFilter ? alsoFilter(item) : true;
        });

        // compute overall totals
        const skip = new Set([ 'name', 'previous', 'next', 'start' ]);
        const totals = { name: 'Total' };
        const groups = Object.values(rollup).sort((a, b) => a.start - b.start);
        groups.forEach((entry, idx) => {
            // assign pointers
            entry.previous = groups[idx - 1];
            entry.next = groups[idx + 1];

            // sum up totals
            Object.keys(entry).filter(key => !skip.has(key)).forEach(key => totals[key] = (totals[key] || 0) + entry[key]);
        });

        return { collection, rollup, groups, totals };
    }

    // prepares rendering hints for progress bars
    hints = (collection, stats, displayStats) => {
        let last = '-start-';
        collection.forEach((item, idx) => {
            // reset any previous render hints
            item.before = null;
            item.after = null;

            // setup hints
            const current = item.group;
            if (current !== last) {
                item.before = stats.rollup[current];
                if (idx > 0) collection[idx - 1].after = stats.rollup[current];

                last = current;
            }
        });

        // link display values from the real ones
        Object.keys(stats.rollup).forEach(key => {
            stats.rollup[key].display = displayStats.rollup[key];
        });
        stats.totals.display = displayStats.totals;
    }

    compute = () => {
        const collection = this.collection();
        if (!collection) return this.setState({ filtered: null, collapsed: null, groups: null, totals: null });

        // first pass: don't filter collected status, generate progress bar stats
        const prep = this.sort(collection.filter(this.filter(false)));
        const displayStats = this.stats(prep, this.filter(true));
        const filtered = displayStats.collection;

        // second pass: finish filtering, generate paging stats
        const stats = this.stats(filtered, item => !this.props.collection.collapsed.has(item.group));
        const collapsed = stats.collection;

        // process rendering hints
        this.hints(filtered, stats, displayStats);

        this.setState({ filtered, collapsed, groups: stats.groups, totals: stats.totals });
    }

    // before shows group + anything collapsed before it
    before = (group) => {
        if (!group) return null;
        if (this.props.collection.collapsed.has(group.previous?.name)) return [ ...this.before(group.previous), group ];
        return [ group ];
    }

    // only show after if they are ALL collapsed
    after = (group) => {
        if (!group) return null;
        if (!this.props.collection.collapsed.has(group.name)) return null;
        if (!group.next) return [ group ];

        const array = this.after(group.next);
        return array ? [ group, ...array ] : null;
    }

    build = (page) => {
        const tpc = this.props.collection;

        if (!this.state.collapsed?.length && this.state.groups?.length) {
            // edge case - everything is collapsed
            return this.state.groups.map(group => <Progress key={group.name} group={group} groups={this.state.groups} collapsed={tpc.collapsed} browse={this.props.browse} split={this.props.split} onToggle={this.props.onToggle} />);
        }

        if (!page?.length) {
            return 'No matches found';
        }

        const showCollected = (this.props.showCollected !== false) && !tpc.showAlts;
        const showDetails = !tpc.showAlts && !tpc.showAppearances;

        return page.map(item =>
            <React.Fragment key={item.id}>
                {this.before(item.before)?.map(group => <Progress key={group.name} group={group} groups={this.state.groups} collapsed={tpc.collapsed} browse={this.props.browse} split={this.props.split} onToggle={this.props.onToggle} />)}
                <Tag item={item} showCollected={showCollected} showDetails={showDetails} showRarity={!tpc.showAlts} onCollectedBy={this.props.onCollectedBy} />
                {this.after(item.after)?.map(group => <Progress key={group.name} group={group} groups={this.state.groups} collapsed={tpc.collapsed} browse={this.props.browse} split={this.props.split} onToggle={this.props.onToggle} />)}
            </React.Fragment>
        );
    }

    render() {
        const tpc = this.props.collection;

        let collection = this.state.collapsed;
        if (!collection) return <Loading message={tpc.message} />;

        const showToggles = !this.props.split && !tpc.showAlts;
        const showCollected = (this.props.showCollected !== false);

        const totalSize = collection.length;
        const pageStart = tpc.start;
        let pageEnd = tpc.start + tpc.pageSize;
        collection = collection.slice(pageStart, pageEnd);
        pageEnd = pageStart + collection.length; // fix size for last page

        const contributor = login.contributor();
        const bulkEditCategory = contributor && !tpc.showAlts && collection?.[0]?.field;
        const bulkEditIds = contributor && collection.map(c => c.id).join('.');

        // only show upload/download for 1) applicable tabs 2) looking at own profile 3) on desktop
        const showDownload = !tpc.showAlts && this.props.myself && window.bootstrap.md.matches;
        const showUpload = (tpc.showManuscripts || tpc.showAppearancesources || tpc.showToys || tpc.showPets || tpc.showQuests) && this.props.myself && window.bootstrap.md.matches;

        const rarityInDefaultState = !tpc.rarity?.mintext && !tpc.rarity?.maxtext && !tpc.seen?.mintext && !tpc.seen?.maxtext;
        const levelInDefaultState = !tpc.level?.mintext && !tpc.level?.maxtext;
        const advancedInDefaultState =
            tpc.showObtainable && tpc.showTimeLimited && (!showCollected || tpc.showCollectedUnobtainable) && tpc.showUnobtainable && tpc.showBugged && !tpc.showNeverImplemented // obtainable group
            && tpc.showPresent && tpc.showMissing // present/missing group
            && tpc.showIncluded && !tpc.showExcluded // excluded group
            && (!tpc.showPets || tpc.showTradable) && (!tpc.showPets || tpc.showNotTradable) // tradable group
            && !tpc.showCollectedExclusive; // exclusive mode

        return (
            <div>
                {showToggles && !this.props.browse && tpc.showManuscripts ? <div className="mb-3">Blizzard's API does not currently provide a list of collected manuscripts. You can instead upload data from an addon. <Link className="small" to="/about#upload">More Info...</Link></div> : null}
                {showToggles && !this.props.browse && tpc.showAppearancesources ? <div className="mb-3">Blizzard's API does not currently provide a list of collected appearance sources. Your leaderboard count is obtained from achievement data. You can upload data from an addon. <Link className="small" to="/about#upload">More Info...</Link></div> : null}

                <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                    <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100') + ' flex-wrap mb-3 mr-3 position-relative'}>
                        <input type="text" placeholder="Search" className={'form-control pr-4' + (window.bootstrap.md.matches ? '' : ' flex-fill')} value={tpc.filter} onChange={this.changeFilter} />
                        <button type="button" title="Clear" className="btn btn-clear" onClick={this.changeFilter}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    {showToggles && showCollected ?
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <ToggleButton label="Collected" checked={tpc.showCollectedThis} onToggle={this.toggleCollectedThis} />
                            <ToggleButton label="Collected on Alt" checked={tpc.showCollectedAlt} onToggle={this.toggleCollectedAlt} />
                            <ToggleButton label="Not Collected" checked={tpc.showNotCollected} onToggle={this.toggleNotCollected} />
                        </div>
                    : null}

                    <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                        <CollapseButton label="Faction" open={tpc.showFactions} default={!tpc.hideFactions.size} onToggle={this.props.onToggle} arg="factions" visible={FILTERS.factions.tabs.has(tpc.show)} />
                        <CollapseButton label="Race" open={tpc.showRaces} default={!tpc.hideRaces.size} onToggle={this.props.onToggle} arg="races" visible={FILTERS.races.tabs.has(tpc.show)} />
                        <CollapseButton label="Class" open={tpc.showClasses} default={!tpc.hideClasses.size} onToggle={this.props.onToggle} arg="classes" visible={FILTERS.classes.tabs.has(tpc.show)} />
                        <CollapseButton label="Expansion" open={tpc.showPatches} default={!tpc.hidePatches.size} onToggle={this.props.onToggle} arg="patches" visible={FILTERS.patches.tabs.has(tpc.show)} />
                        <CollapseButton label="Source" open={tpc.showSources} default={!tpc.hideSources.size} onToggle={this.props.onToggle} arg="sources" visible={FILTERS.sources.tabs.has(tpc.show)} />
                        <CollapseButton label="Slot" open={tpc.showSlots} default={!tpc.hideSlots.size} onToggle={this.props.onToggle} arg="slots" visible={FILTERS.slots.tabs.has(tpc.show)} />
                        <CollapseButton label="Type" open={tpc.showTypes} default={!tpc.hideTypes.size} onToggle={this.props.onToggle} arg="types" visible={FILTERS.types.tabs.has(tpc.show)} />
                        <CollapseButton label="Quality" open={tpc.showQualities} default={!tpc.hideQualities.size} onToggle={this.props.onToggle} arg="qualities" visible={FILTERS.qualities.tabs.has(tpc.show) && (!tpc.showPets || showCollected)} />
                        <CollapseButton label="Level" open={tpc.showLevels} default={levelInDefaultState} onToggle={this.props.onToggle} arg="levels" visible={FILTERS.levels.tabs.has(tpc.show) && showCollected} />
                        <CollapseButton label="Binds" open={tpc.showBinds} default={!tpc.hideBinds.size} onToggle={this.props.onToggle} arg="binds" visible={FILTERS.binds.tabs.has(tpc.show)} />
                        <CollapseButton label="Category" open={tpc.showCategories} default={!tpc.hideCategories.size} onToggle={this.props.onToggle} arg="categories" visible={FILTERS.categories.tabs.has(tpc.show)} />
                        <CollapseButton label="Category" open={this.state.showZones} default={!tpc.hideZones.size} onToggle={this.toggleZones} visible={FILTERS.zones.tabs.has(tpc.show)} />
                        <CollapseButton label="Profession" open={tpc.showProfessions} default={!tpc.hideProfessions.size} onToggle={this.props.onToggle} arg="professions" visible={FILTERS.professions.tabs.has(tpc.show)} />
                        <CollapseButton label="Rarity" open={tpc.showRarity} default={rarityInDefaultState} onToggle={this.props.onToggle} arg="rarities" visible={FILTERS.rarities.tabs.has(tpc.show)} />
                        <CollapseButton label="Advanced" open={tpc.showAdvanced} default={advancedInDefaultState} onToggle={this.props.onToggle} arg="advanced" visible={!tpc.showAlts} />
                    </div>
                    {tpc.showAlts ? null :
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <CollapseButton label="Sort" open={this.state.showSort} onToggle={this.toggleSort} />
                            <CollapseButton label="Group" open={this.state.showGroup} onToggle={this.toggleGroup} />
                        </div>
                    }

                    <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                        <Tooltip title="Reset All Filters">
                            <button type="button" className="btn btn-primary" onClick={this.resetFilters}><FontAwesomeIcon icon={faUndo} /></button>
                        </Tooltip>
                    </div>

                    {showDownload || showUpload ?
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            {showDownload ? <Tooltip title="Download CSV File"><button type="button" className="btn btn-primary" onClick={this.download}><FontAwesomeIcon icon={faFileDownload} /></button></Tooltip> : null}
                            {showUpload ? <Tooltip title="Upload Addon Data"><button type="button" className="btn btn-primary" onClick={this.toggleUpload}><FontAwesomeIcon icon={faFileUpload} /></button></Tooltip> : null}
                        </div>
                    : null}

                    {bulkEditCategory ?
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <Tooltip title="Bulk Report">
                                <Link to={'/collections/' + bulkEditCategory + '/' + bulkEditIds} target="_blank" className="btn btn-primary"><FontAwesomeIcon icon={farFlag} /></Link>
                            </Tooltip>
                        </div>
                    : null}
                </div>
                {tpc.showFactions ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Faction</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <CollapseButton onToggle={this.props.onToggle} arg="factions" />
                            {tpc.showAlts ? null : <ToggleButtonWrapper id={undefined} label="Neutral" property="faction" checked={!tpc.hideFactions.has(undefined)} onToggle={this.props.onToggle} />}
                            <ToggleButtonWrapper id={0} label="Alliance" property="faction" checked={!tpc.hideFactions.has(0)} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id={1} label="Horde" property="faction" checked={!tpc.hideFactions.has(1)} onToggle={this.props.onToggle} />
                        </div>
                    </div>
                : null}
                {tpc.showRaces ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Race</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <CollapseButton onToggle={this.props.onToggle} arg="races" />
                                <ToggleButtonWrapper id="All" property="race" variants={utils.races.map(c => c.key)} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="race" variants={utils.races.map(c => c.key)} onToggle={this.props.onToggle} />
                            </div>
                            {utils.races.map(c => <ToggleButtonWrapper key={c.key} id={c.key} label={c.value} property="race" checked={!tpc.hideRaces.has(c.key)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                {tpc.showClasses ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Class</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <CollapseButton onToggle={this.props.onToggle} arg="classes" />
                                <ToggleButtonWrapper id="All" property="class" variants={utils.classes.map(c => c.key)} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="class" variants={utils.classes.map(c => c.key)} onToggle={this.props.onToggle} />
                            </div>
                            {utils.classes.map(c => <ToggleButtonWrapper key={c.key} id={c.key} label={c.value} property="class" checked={!tpc.hideClasses.has(c.key)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                {tpc.showSources ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Source</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <CollapseButton onToggle={this.props.onToggle} arg="sources" />
                                <ToggleButtonWrapper id="All" property="source" variants={utils.sourceicons} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="source" variants={utils.sourceicons} onToggle={this.props.onToggle} />
                            </div>
                            {utils.sourceicons.map(key => <ToggleButtonWrapper key={key} id={key} label={utils.getFriendlySourceName(key)} property="source" checked={!tpc.hideSources.has(key)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                {tpc.showBinds ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Binds</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <CollapseButton onToggle={this.props.onToggle} arg="binds" />
                            <ToggleButtonWrapper id={0} label="on Pickup" property="bind" checked={!tpc.hideBinds.has(0)} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id={1} label={tpc.showAppearances || tpc.showAppearancesources ? 'on Equip' : 'on Use'} property="bind" checked={!tpc.hideBinds.has(1)} onToggle={this.props.onToggle} />
                        </div>
                    </div>
                : null}
                {tpc.showProfessions ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Profession</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <CollapseButton onToggle={this.props.onToggle} arg="professions" />
                                <ToggleButtonWrapper id="All" property="profession" variants={[ '', 'Alchemy', 'Archaeology', 'Blacksmithing', 'Cooking', 'Enchanting', 'Engineering',  'Goblin Engineering',  'Gnomish Engineering', 'Fishing', 'Herbalism', 'Inscription', 'Jewelcrafting', 'Leatherworking', 'Mining', 'Skinning', 'Tailoring' ]} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="profession" variants={[ '', 'Alchemy', 'Archaeology', 'Blacksmithing', 'Cooking', 'Enchanting', 'Engineering',  'Goblin Engineering',  'Gnomish Engineering', 'Fishing', 'Herbalism', 'Inscription', 'Jewelcrafting', 'Leatherworking', 'Mining', 'Skinning', 'Tailoring' ]} onToggle={this.props.onToggle} />
                            </div>
                            {tpc.showQuests ? <ToggleButtonWrapper id="" label="Neutral" property="profession" checked={!tpc.hideProfessions.has('')} onToggle={this.props.onToggle} /> : null}
                            <ToggleButtonWrapper id="Alchemy" property="profession" checked={!tpc.hideProfessions.has('Alchemy')} onToggle={this.props.onToggle} />
                            {tpc.showQuests ? <ToggleButtonWrapper id="Archaeology" property="profession" checked={!tpc.hideProfessions.has('Archaeology')} onToggle={this.props.onToggle} /> : null}
                            <ToggleButtonWrapper id="Blacksmithing" property="profession" checked={!tpc.hideProfessions.has('Blacksmithing')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Cooking" property="profession" checked={!tpc.hideProfessions.has('Cooking')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Enchanting" property="profession" checked={!tpc.hideProfessions.has('Enchanting')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Engineering" property="profession" checked={!tpc.hideProfessions.has('Engineering')} variants={[ 'Engineering', 'Goblin Engineering', 'Gnomish Engineering' ]} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Fishing" property="profession" checked={!tpc.hideProfessions.has('Fishing')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Herbalism" property="profession" checked={!tpc.hideProfessions.has('Herbalism')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Inscription" property="profession" checked={!tpc.hideProfessions.has('Inscription')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Jewelcrafting" property="profession" checked={!tpc.hideProfessions.has('Jewelcrafting')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Leatherworking" property="profession" checked={!tpc.hideProfessions.has('Leatherworking')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Mining" property="profession" checked={!tpc.hideProfessions.has('Mining')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Skinning" property="profession" checked={!tpc.hideProfessions.has('Skinning')} onToggle={this.props.onToggle} />
                            <ToggleButtonWrapper id="Tailoring" property="profession" checked={!tpc.hideProfessions.has('Tailoring')} onToggle={this.props.onToggle} />
                        </div>
                    </div>
                : null}
                {tpc.showCategories ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Category</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <CollapseButton onToggle={this.props.onToggle} arg="categories" />
                                <ToggleButtonWrapper id="All" property="category" variants={tpc.categories} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="category" variants={tpc.categories} onToggle={this.props.onToggle} />
                            </div>
                            {tpc.categories.map(c => <ToggleButtonWrapper key={c} id={c} property="category" checked={!tpc.hideCategories.has(c)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                {tpc.showSlots ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Slot</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <CollapseButton onToggle={this.props.onToggle} arg="slots" />
                                <ToggleButtonWrapper id="All" property="slot" variants={utils.slots} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="slot" variants={utils.slots} onToggle={this.props.onToggle} />
                            </div>
                            {utils.slots.map(c => <ToggleButtonWrapper key={c} id={c} property="slot" checked={!tpc.hideSlots.has(c)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                {tpc.showTypes ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Type</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <CollapseButton onToggle={this.props.onToggle} arg="types" />
                                <ToggleButtonWrapper id="All" property="type" variants={utils.geartypes} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="type" variants={utils.geartypes} onToggle={this.props.onToggle} />
                            </div>
                            {utils.geartypes.map(c => <ToggleButtonWrapper key={c} id={c} property="type" checked={!tpc.hideTypes.has(c)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                {tpc.showQualities ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Quality</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <CollapseButton onToggle={this.props.onToggle} arg="qualities" />
                                <ToggleButtonWrapper id="All" property="quality" variants={utils.qualities.map(c => c.key)} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="quality" variants={utils.qualities.map(c => c.key)} onToggle={this.props.onToggle} />
                            </div>
                            {utils.qualities.filter(c => !tpc.showPets || c.key < 4).map(c => <ToggleButtonWrapper key={c.key} id={c.key} label={c.value} property="quality" checked={!tpc.hideQualities.has(c.key)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                <Popper open={this.state.showZones && this.props.split !== 'right'} anchorEl={this.state.showZones} placement="bottom-start">
                    <ClickAwayListener onClickAway={this.toggleZones}>
                        <div className="card mt-1">
                            <div className="btn-group-vertical">
                                <div className="btn-group">
                                    <ToggleButtonWrapper id="All" property="zone" variants={this.state.zones} onToggle={this.props.onToggle} />
                                    <ToggleButtonWrapper id="None" property="zone" variants={this.state.zones} onToggle={this.props.onToggle} />
                                </div>
                                <div className="btn-group-vertical scroller">
                                    {this.state.zones.map(c => <ToggleButtonWrapper key={c} id={c} property="zone" checked={!tpc.hideZones.has(c)} onToggle={this.props.onToggle} />)}
                                </div>
                            </div>
                        </div>
                    </ClickAwayListener>
                </Popper>
                {tpc.showPatches ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Expansion</div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                            <div className="btn-group">
                                <CollapseButton onToggle={this.props.onToggle} arg="patches" />
                                <ToggleButtonWrapper id="All" property="patch" variants={utils.patches.all} onToggle={this.props.onToggle} />
                                <ToggleButtonWrapper id="None" property="patch" variants={utils.patches.all} onToggle={this.props.onToggle} />
                            </div>
                            {Object.keys(utils.expansions).map(ver => <ToggleButtonWrapper key={ver} id={utils.expansions[ver]} property="patch" checked={!tpc.hidePatches.has(utils.patches[ver][0])} variants={utils.patches[ver]} onToggle={this.props.onToggle} />)}
                        </div>
                        <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                            {utils.patches.v11.filter(p => !p.includes('x')).map(p => <ToggleButtonWrapper key={p} id={p} property="patch" checked={!tpc.hidePatches.has(p)} onToggle={this.props.onToggle} />)}
                        </div>
                    </div>
                : null}
                {tpc.showRarity ?
                    <div className={window.bootstrap.md.matches ? 'd-flex' : ''} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="mb-3">
                            <div className="small mb-1">Rarity</div>
                            <div>
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100 mb-3')  + ' flex-wrap mr-3'}>
                                    <CollapseButton onToggle={this.props.onToggle} arg="rarities" />
                                </div>
                                <div className="btn-group mr-3">
                                    <div className="my-auto"><NumberInput placeholder="Min" className="form-control" value={tpc.rarity.mintext} onChange={this.changeRarityMin} /></div>
                                    <div className="my-auto mx-1">-</div>
                                    <div className="my-auto"><NumberInput placeholder="Max" className="form-control" value={tpc.rarity.maxtext} onChange={this.changeRarityMax} /></div>
                                    <div className="my-auto ml-1">%</div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <div className="small mb-1">Seen</div>
                            <div className="btn-group">
                                <div className="my-auto"><NumberInput placeholder="From" className="form-control" value={tpc.seen.maxtext} onChange={this.changeSeenMax} /></div>
                                <div className="my-auto mx-1">-</div>
                                <div className="my-auto"><NumberInput placeholder="To" className="form-control" value={tpc.seen.mintext} onChange={this.changeSeenMin} /></div>
                                <div className="my-auto ml-1">days ago</div>
                            </div>
                        </div>
                    </div>
                : null}
                {tpc.showLevels ?
                    <div className={window.bootstrap.md.matches ? 'd-flex' : ''} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="mb-3">
                            <div className="small mb-1">Level</div>
                            <div>
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100 mb-3')  + ' flex-wrap mr-3'}>
                                    <CollapseButton onToggle={this.props.onToggle} arg="levels" />
                                </div>
                                <div className="btn-group">
                                    <div className="my-auto"><NumberInput placeholder="From" className="form-control" value={tpc.level.mintext} onChange={this.changeLevelMin} /></div>
                                    <div className="my-auto mx-1">-</div>
                                    <div className="my-auto"><NumberInput placeholder="To" className="form-control" value={tpc.level.maxtext} onChange={this.changeLevelMax} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                : null}
                {tpc.showAdvanced ?
                    <div style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <div className="small mb-1">Advanced</div>
                        <div>
                            <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'}>
                                <CollapseButton onToggle={this.props.onToggle} arg="advanced" />
                            </div>
                            <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                <button type="button" className={tpc.showObtainable ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleObtainable}><FontAwesomeIcon icon={tpc.showObtainable ? faCheckCircle : faCircle} /> Obtainable</button>
                                <button type="button" className={tpc.showTimeLimited ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleTimeLimited}><FontAwesomeIcon icon={tpc.showTimeLimited ? faCheckCircle : faCircle} /> Obtainable for Limited Time</button>
                                {showToggles && showCollected ? <button type="button" className={tpc.showCollectedUnobtainable ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleCollectedUnobtainable}><FontAwesomeIcon icon={tpc.showCollectedUnobtainable ? faCheckCircle : faCircle} /> Unobtainable (Collected)</button> : null}
                                <button type="button" className={tpc.showUnobtainable ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleUnobtainable}><FontAwesomeIcon icon={tpc.showUnobtainable ? faCheckCircle : faCircle} /> Unobtainable</button>
                                <button type="button" className={tpc.showBugged ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleBugged}><FontAwesomeIcon icon={tpc.showBugged ? faCheckCircle : faCircle} /> Bugged</button>
                                <button type="button" className={tpc.showNeverImplemented ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleNeverImplemented}><FontAwesomeIcon icon={tpc.showNeverImplemented ? faCheckCircle : faCircle} /> Never Implemented</button>
                            </div>

                            <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                <button type="button" className={tpc.showPresent ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.togglePresent}><FontAwesomeIcon icon={tpc.showPresent ? faCheckCircle : faCircle} /> Present in API</button>
                                <button type="button" className={tpc.showMissing ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleMissing}><FontAwesomeIcon icon={tpc.showMissing ? faCheckCircle : faCircle} /> Missing from API</button>
                            </div>

                            <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                <button type="button" className={tpc.showIncluded ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleIncluded}><FontAwesomeIcon icon={tpc.showIncluded ? faCheckCircle : faCircle} /> Included in Ranking</button>
                                <button type="button" className={tpc.showExcluded ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleExcluded}><FontAwesomeIcon icon={tpc.showExcluded ? faCheckCircle : faCircle} /> Excluded from Ranking</button>
                            </div>

                            {tpc.showPets ?
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                    <button type="button" className={tpc.showTradable ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleTradable}><FontAwesomeIcon icon={tpc.showTradable ? faCheckCircle : faCircle} /> Tradable</button>
                                    <button type="button" className={tpc.showNotTradable ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleNotTradable}><FontAwesomeIcon icon={tpc.showNotTradable ? faCheckCircle : faCircle} /> Not Tradable</button>
                                </div>
                            : null}

                            {showToggles && showCollected ?
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                    <button type="button" className={tpc.showCollectedExclusive ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleCollectedExclusive}><FontAwesomeIcon icon={tpc.showCollectedExclusive ? faCheckCircle : faCircle} /> Exclusive to This Character</button>
                                </div>
                            : null}

                            {!this.props.split && (tpc.showAppearancesources || tpc.showAppearances) ?
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                    {tpc.showAppearancesources ? <Link to="appearances" className="btn btn-primary"><FontAwesomeIcon icon={faCircle} /> Unique Only</Link> : null}
                                    {tpc.showAppearances ? <Link to="appearancesources" className="btn btn-primary active"><FontAwesomeIcon icon={faCheckCircle} /> Unique Only</Link> : null}
                                </div>
                            : null}

                            <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mb-3 mr-3'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                <button type="button" className={localStorage.rememberFilters ? 'btn btn-primary active' : 'btn btn-primary'} onClick={this.toggleRememberFilter}><FontAwesomeIcon icon={localStorage.rememberFilters ? faCheckCircle : faCircle} /> Remember Filters</button>
                            </div>
                        </div>
                    </div>
                : null}
                <Popper open={this.state.showSort && this.props.split !== 'right'} anchorEl={this.state.showSort} placement="bottom-start">
                    <ClickAwayListener onClickAway={this.toggleSort}>
                        <div className="card mt-1">
                            <div className="btn-group-vertical scroller">
                                <SortButton label="Name" sort="name" active={tpc.sort} visible={tpc.showSortName} onToggle={this.props.onToggle} arg="sortby" />
                                <SortButton label="Source" sort="source" active={tpc.sort} visible={tpc.showSortSource} onToggle={this.props.onToggle} arg="sortby" />
                                <SortButton label="Profession" sort="profession" active={tpc.sort} visible={tpc.showSortProfession} onToggle={this.props.onToggle} arg="sortby" />
                                <SortButton label="Category" sort="category" active={tpc.sort} visible={tpc.showSortCategory} onToggle={this.props.onToggle} arg="sortby" />
                                <SortButton label="Rarity" sort="rarity" active={tpc.sort} visible={tpc.showSortRarity} onToggle={this.props.onToggle} arg="sortby" />
                                <SortButton label="Patch" sort="patch" active={tpc.sort} visible={tpc.showSortPatch} onToggle={this.props.onToggle} arg="sortby" />
                                <SortButton label="ID" sort="id" active={tpc.sort} visible={tpc.showSortId} onToggle={this.props.onToggle} arg="sortby" />
                            </div>
                        </div>
                    </ClickAwayListener>
                </Popper>
                <Popper open={this.state.showGroup && this.props.split !== 'right'} anchorEl={this.state.showGroup} placement="bottom-start">
                    <ClickAwayListener onClickAway={this.toggleGroup}>
                        <div className="card mt-1">
                            <div className="btn-group-vertical scroller">
                                <SortButton label="None" sort="null" active={tpc.group} visible={true} onToggle={this.props.onToggle} arg="groupby" />
                                <SortButton label="Source" sort="source" active={tpc.group} visible={tpc.showSortSource} onToggle={this.props.onToggle} arg="groupby" />
                                <SortButton label="Profession" sort="profession" active={tpc.group} visible={tpc.showSortProfession} onToggle={this.props.onToggle} arg="groupby" />
                                <SortButton label="Category" sort="category" active={tpc.group} visible={tpc.showSortCategory} onToggle={this.props.onToggle} arg="groupby" />
                                <SortButton label="Expansion" sort="patch" active={tpc.group} visible={tpc.showSortPatch} onToggle={this.props.onToggle} arg="groupby" />
                            </div>
                        </div>
                    </ClickAwayListener>
                </Popper>
                <Loading message={tpc.message} />
                <div id="collection" className={'collection' + (this.props.split ? '' : ' collection-nosplit')}>
                    {pageStart === 0 ? <Progress group={this.state.totals} groups={this.state.groups} collapsed={tpc.collapsed} onToggle={this.props.onToggle} browse={this.props.browse} split={this.props.split} total={true} filtered={this.collection()?.length - this.state.totals?.display?.total} /> : null}
                    {this.build(collection)}
                </div>
                {collection.length > 0 ?
                    <div className="mt-3" style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <Paging start={pageStart} end={pageEnd} pageSize={tpc.pageSize} pageSizes={[ 40, 60, 120, 180 ]} totalSize={totalSize} onClick={this.setPage} />
                    </div>
                : null}
                <AddonUpload open={this.state.showUpload} category={tpc.show} onClose={this.finishUpload} />
            </div>
        );
    }
}