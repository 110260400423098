import moment from 'moment';
import utils from './utils';

function download(data, filename, type) {
    const element = document.createElement("a");
    const file = new Blob([ data ], { type });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
}

function csv(collection, tpc) {
    const timestamp = moment.utc().format('YYYYMMDD.hhmmss');

    const showAppearances = tpc.showAppearances || tpc.showAppearancesources;
    const showSource = tpc.showMounts || tpc.showPets || tpc.showTitles || tpc.showRecipes || tpc.showToys || tpc.showHeirlooms || tpc.showSoulshapes || tpc.showManuscripts;

    const rows = collection.map(item => [
        // collected status
        item.id,
        item.name,
        item.character ? 'Collected' : (item.account ? 'Collected on Alt' : 'Not Collected'),

        // common info
        utils.unobtainables.find(e => e.key === item.unobtainable)?.value || 'Obtainable',
        item.missing ? 'Missing from API' : 'Present in API',
        item.excluded ? 'Excluded in Ranking' : 'Included in Ranking',
        item.rarity || 0,
        item.patch || '',

        showSource ? ('[' + (item.sourceicon || 'other') + '] ' + (item.source || '') + (item.sourcestanding ? ' (' + item.sourcestanding + ')' : '') + (item.sourcezone ? ' (' + item.sourcezone + ')' : '')) : undefined,

        showAppearances ? (item.slot || 'Other') : undefined,
        showAppearances ? (item.type || 'Other') : undefined,

        tpc.showAchievements ? (item.points || 0) : undefined,
        tpc.showAchievements ? (item.category || '') : undefined,
        tpc.showAchievements ? (item.subcategory || '') : undefined,

        tpc.showRecipes ? (item.prof || '') : undefined,

        tpc.showQuests ? (item.category || '') : undefined
    ]);

    rows.unshift([
        'ID',
        tpc.show + ' for ' + tpc?.character1?.key + ' @ ' + timestamp,
        'Collected',

        'Unobtainable',
        'Missing',
        'Excluded',
        'Rarity',
        'Patch',

        showSource ? 'Source' : undefined,

        showAppearances ? 'Slot' : undefined,
        showAppearances ? 'Type' : undefined,

        tpc.showAchievements ? 'Points' : undefined,
        tpc.showAchievements ? 'Category' : undefined,
        tpc.showAchievements ? 'Sub-Category' : undefined,

        tpc.showRecipes ? 'Profession' : undefined,

        tpc.showQuests ? 'Category' : undefined
    ]);

    const csv = rows.map(row => row.filter(e => e !== undefined).map(e => utils.escapeCsv(e)).join(',')).join('\n');
    download(csv, tpc.show + '.' + timestamp + '.csv', 'text/csv');
}

const ex = { download, csv };
export default ex;