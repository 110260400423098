import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronCircleUp, faChevronDown, faChevronCircleDown, faTimes } from '@fortawesome/free-solid-svg-icons';

class CollapseButton extends Component {
    handleClick = (event) => {
        if (this.props.args) {
            this.props.onToggle(...this.props.args);
        } else if (this.props.arg !== undefined) {
            this.props.onToggle(this.props.arg);
        } else {
            this.props.onToggle(event);
        }
    }

    icon() {
        if (!this.props.label && this.props.open && !window.bootstrap.md.matches) {
            // on mobile, use 'close' icon instead
            return faTimes;
        }

        if (this.props.default) {
            return this.props.open ? faChevronUp : faChevronDown;
        } else {
            return this.props.open ? faChevronCircleUp : faChevronCircleDown;
        }
    }

    render() {
        if (!this.props.visible) return null;

        return (
            <button type="button" className="btn btn-primary text-nowrap" onClick={this.handleClick}>
                {this.props.label} <FontAwesomeIcon icon={this.icon()} />
            </button>
        );
    }
}

CollapseButton.defaultProps = {
    visible: true,
    default: true,
    open: true
};

export default CollapseButton;