import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';

class ToggleButton extends Component {
    render() {
        if (!this.props.visible) return null;

        return (
            <button type="button" className={'btn btn-primary text-nowrap' + (this.props.checked ? ' active' : '')} onClick={this.props.onToggle}>
                <FontAwesomeIcon icon={this.props.checked ? faCheckCircle : faCircle} /> {this.props.label}
            </button>
        );
    }
}

ToggleButton.defaultProps = {
    visible: true
};

export default ToggleButton;